import React, { useContext } from 'react';
import { AppContext } from '~/providers/AppProvider';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { getNextImageElement } from '~/utils/images';
import Link from 'next/link';
import { parseObjToUrl } from '~/utils/urlParser';
import { getCultureToken } from '~/utils/langUtils';

const RegionItem = ({ cityData, carousel }) => {
  const router = useRouter();
  const appCtx = useContext(AppContext);

  const getSearchQuery = () => {
    const searchQuery = {
      latitudeSw: cityData.LatitudeSW,
      longitudeSw: cityData.LongitudeSW,
      latitudeNe: cityData.LatitudeNE,
      longitudeNe: cityData.LongitudeNE,
      currency: appCtx.state.currency,
    };

    if (appCtx.state.defaultCurrency === appCtx.state.currency) {
      delete searchQuery.currency;
    }

    return searchQuery;
  };

  return (
    <Link href={`/${getCultureToken(appCtx)}search${parseObjToUrl(getSearchQuery())}`} passHref={true}>
      <Wrapper className={carousel ? '' : 'animate'} aria-hidden="false">
        <DestinationPicture>{getNextImageElement(cityData.Picture, 356, 230, '356px')}</DestinationPicture>

        <DataWrapper>
          <Country>
            <b>{cityData.Country}</b>
          </Country>
          <City>
            <b>{cityData.Name}</b>
          </City>
        </DataWrapper>
      </Wrapper>
    </Link>
  );
};

const Wrapper = styled.a`
  max-width: 356px;
  min-width: 270px;
  width: 100%;
  height: auto;
  cursor: pointer;
  align-items: center;
  border-radius: 9px;
  text-decoration: none;
  position: relative;
  align-items: flex-end;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 20px;
  transition: transform 0.25s ease-in-out 0s, box-shadow 0.25s ease-in-out 0s;

  @media (max-width: 400px) {
    margin-bottom: 20px;
  }
`;

const Country = styled.div`
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  b {
    font-weight: 500 !important;
  }
`;

const City = styled(Country)`
  font-size: 36px;
  line-height: 42px;

  b {
    font-weight: 600 !important;
  }
`;

const DataWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 25px;
  bottom: 12px;
`;

const DestinationPicture = styled.div`
  max-width: 360px;
  min-width: 270px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background: transparent linear-gradient(180deg, #14213347 0%, #1421335c 65%, #142133b8 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 8px;

  img {
    mix-blend-mode: multiply;
    border-radius: 9px !important;
    object-fit: cover;
    /* filter: brightness(80%); */
    transition: all 0.5s ease-in-out 0s;
  }
`;
export default RegionItem;
