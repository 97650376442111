import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import RegionItem from '~/components/regions/regionItem';
import { SectionWrapperGrey, ContentWrapper } from '~/styles/common';
import Flickity from 'react-flickity-component';
import { Button } from '@boatsetter-backup/js-component-lib';
import { AppContext } from '~/providers/AppProvider';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import { loadFlickity } from '~/utils/externalSourcesLoader';
import { getRegionsPageUrl } from '~/utils/routing';
import Link from 'next/link';

const flickityOptions = {
  initialIndex: 0,
  contain: true,
  groupCells: '100%',
};

const FeaturedDestinations = ({ regions }) => {
  const router = useRouter();
  const appCtx = useContext(AppContext);

  useEffect(() => {
    loadFlickity();
  }, []);

  return (
    <FeaturedDestinationsSection>
      <ContentWrapper>
        <SectionHeading>
          <Trans id="featuredDestinations.heading" />
        </SectionHeading>

        <FlexWrapper>
          <SectionDescription>
            <Trans id="featuredDestinations.description" />
          </SectionDescription>
          <AllDestinationsButton>
            <Link href={`${getRegionsPageUrl(appCtx)}`} passHref={true}>
              <a>
                <Button small={true} text={<Trans id="featuredDestinations.seeAll" />} />
              </a>
            </Link>
          </AllDestinationsButton>
        </FlexWrapper>
        <RegionsWrapper>
          <Flickity
            className={'carousel'}
            elementType={'div'}
            options={flickityOptions}
            disableImagesLoaded={true}
            static
          >
            {regions.map((city) => (
              <RegionItem key={city.Id} cityData={city} carousel={true} />
            ))}
          </Flickity>
        </RegionsWrapper>
      </ContentWrapper>
    </FeaturedDestinationsSection>
  );
};

export default FeaturedDestinations;

const FeaturedDestinationsSection = styled(SectionWrapperGrey)`
  padding-bottom: 2em;
`;

const SectionHeading = styled.h2`
  margin-bottom: 0.5em;
  font-size: 2em;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 28px;
  flex-wrap: wrap;
  margin-right: 2em;

  @media screen and (max-width: 500px) {
    margin-right: 0em;
  }
`;

const SectionDescription = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #4b5765;
  margin-bottom: 1em;
`;

const RegionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2em;
  margin-left: 0em;

  @media screen and (max-width: 1480px) {
    margin-right: 0em;
    margin-left: 0em;

    .flickity-prev-next-button.next {
      display: none;
    }

    .flickity-prev-next-button.previous {
      display: none;
    }
  }

  .flickity-prev-next-button {
    background: transparent;
  }
  .flickity-button-icon {
    color: #8e9697;
  }

  .flickity-prev-next-button.next {
    right: -65px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #8e9697;
  }

  .flickity-prev-next-button.previous {
    left: -65px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #8e9697;
  }
`;

const AllDestinationsButton = styled.div`
  button span {
    font-size: 12px !important;
    font-family: AvenirNext, Avenir, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
`;
