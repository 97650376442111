const loadFlickityJs = () => {
  new Promise((resolve, reject) => {
    const flickity = document.createElement('script');
    flickity.setAttribute('src', `https://unpkg.com/flickity@2/dist/flickity.pkgd.min.js`);
    document.head.appendChild(flickity);
  }).then(() => {});
};

const loadFlickityCss = () => {
  const head = document.head;
  const link = document.createElement('link');

  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = 'https://unpkg.com/flickity@2/dist/flickity.min.css';

  head.appendChild(link);
};

const loadFlickity = () => {
  loadFlickityJs();
  loadFlickityCss();
};

export { loadFlickity };
